<template>
  <navbar />
  <page-title :title="this.form.name" />
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <div class="max-w-full mx-4 py-6 sm:mx-auto">
      <div class="sm:flex sm:space-x-4">
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/2"
        >
          <div class="bg-white p-5">
            <div class="sm:flex sm:items-start">
              <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                <p class="text-3xl font-bold text-black">
                  {{ detailTransactionProduct.total_qty }}
                </p>
                <p
                  v-if="this.start_date != '' && isSubmit"
                  class="text-base text-gray-500"
                >
                  <b>{{ this.form.name }}</b> Terjual pada
                  {{ formattedCreatedAt(this.start_date) }} sampai
                  {{ formattedCreatedAt(this.end_date) }}
                </p>
                <p v-else class="text-sm text-gray-500">
                  <b>{{ this.form.name }}</b> terjual hari ini.
                </p>

                <p class="text-xs">
                  Item yang direject (canceled) tidak dihitung.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="max-w-full mx-4 pb-6 sm:mx-auto flex flex-col lg:flex-row items-center w-full"
    >
      <div class="w-full rounded mr-1">
        <div>
          <label class="font-medium">Start Date:</label>
          <input
            v-model="this.start_date"
            autocomplete="off"
            type="date"
            name="start_date"
            placeholder="From"
            class="relative w-full px-4 py-2 mt-2 border border-gray-300 rounded placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm"
          />
        </div>
      </div>

      <div class="w-full rounded mr-1">
        <div>
          <label class="font-medium">End Date:</label>
          <input
            v-model="this.end_date"
            autocomplete="off"
            type="date"
            name="end_date"
            placeholder="To"
            class="relative w-full px-4 py-2 mt-2 border border-gray-300 rounded placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm"
          />
        </div>
      </div>

      <div class="w-full rounded mr-1 mt-8">
        <div>
          <button
            @click="search"
            type="button"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <div v-if="loading" class="btn-spinner mt-1 mr-2" />
            Search
          </button>

          <button
            @click="reset"
            type="button"
            class="ml-3 text-sm text-gray-500 hover:text-gray-700 focus:text-indigo-500"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-0 pb-40">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="-my-2 overflow-x-auto">
          <div class="py-2 align-middle inline-block min-w-full">
            <div v-if="detailTransactionProduct.length == 0">
              <p>No transactions for the {{ this.productName }} today yet.</p>
            </div>
            <div
              v-else
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Code
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Payment Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    @click="openDetailTransaction(item.transaction.id)"
                    class="hover:bg-gray-50 cursor-pointer"
                    v-for="item in detailTransactionProduct.data"
                    :key="item.id"
                  >
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm text-gray-900">
                            {{ item.transaction.transaction_code }} <br />
                            {{ item.transaction.customer.name }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ formattedCreatedAt(item.created_at) }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ item.quantity }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ item.transaction.payment_status }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ currency(item.price) }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ currency(item.total) }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import PageTitle from "@/components/PageTitle";

export default {
  components: {
    Navbar,
    PageTitle,
  },
  name: "DetailTransactionProduct",
  props: ["productName"],
  data() {
    return {
      start_date: "",
      end_date: "",
      isSubmit: false,
    };
  },
  computed: {
    ...mapState("products", {
      detailTransactionProduct: (state) => state.detailTransactionProduct,
      form: (state) => state.form,
      loading: (state) => state.loading,
    }),
    currency() {
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return (price) => formatter.format(price);
    },
    formattedCreatedAt() {
      return (created_at) => {
        const date = new Date(created_at);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      };
    },
  },
  mounted() {
    this.getDetailTransactionProduct();
    this.getProductInfo();
  },
  methods: {
    getProductInfo() {
      this.$store.dispatch("products/editProduct", this.$route.params.id);
    },
    getDetailTransactionProduct() {
      this.$store.dispatch("products/getDetailTransactionProduct", {
        id: this.$route.params.id,
        start_date: "",
        end_date: "",
      });
    },
    openDetailTransaction(id) {
      const route = this.$router.resolve({
        name: "TransactionsDetail",
        params: { id },
      });
      window.open(route.href, "_blank");
    },
    search() {
      if (this.start_date == "") {
        alert("Please enter start date.");
        return;
      }

      if (this.end_date == "") {
        alert("Please enter end date.");
        return;
      }

      if (new Date(this.end_date) < new Date(this.start_date)) {
        alert(
          "The end date should be larger than or the same as the start date."
        );
        return;
      }

      this.$router.push({
        query: Object.assign(
          {},
          {
            start_date: this.start_date,
            end_date: this.end_date,
          }
        ),
      });

      this.$store.dispatch("products/getDetailTransactionProduct", {
        id: this.$route.params.id,
        start_date: this.start_date,
        end_date: this.end_date,
      });

      this.isSubmit = true;
    },
    reset() {
      this.start_date = "";
      this.end_date = "";
      this.isSubmit = false;

      this.$store.dispatch("products/getDetailTransactionProduct", {
        id: this.$route.params.id,
        start_date: "",
        end_date: "",
      });
    },
  },
  // watch: {
  //   $route(to, from) {
  //     // Watch only the ?page query string
  //     if (to.query.page !== from.query.page) {
  //       this.getDetailTransactionProduct();
  //     }
  //   },
  // },
};
</script>
